:root {
  --bg1      : #E9EAEC;
  --bg2      : #F4F5F6;
  --bg3      : #fff;
  --font-base: 'Inter', arial, helvetica, sans-serif;
  --font-alt : adonisc, 'Times New Roman', Times, serif;

}

[data-theme="dark"] {
  --bg1: #181818;
  --bg2: #282828;
  --bg3: #323232;
}

@layer components {
  .active-enabled:not(:is(:is(.toggle-active, .tab-active, .active) .active-enabled)) {
    display: none !important;
  }

  .active-disabled:is(:is(.toggle-active, .tab-active, .active) .active-disabled) {
    display: none !important;
  }

  .is-active-disabled:is(.toggle-active, .active) {
    display: none;
  }

  .is-active-enabled:not(:is(.toggle-active, .active)) {
    display: none;
  }

  .title {
    font-family: var(--font-alt);
    font-size  : 48px;
    line-height: 1.1;

    @media (max-width:theme('screens.xl')) {
      font-size: 40px;
    }

    @media (max-width:theme('screens.md')) {
      font-size: 32px;
    }
  }

  .slider-pagination {
    --swiper-pagination-color: #000;

    position : relative;
    top      : auto !important;
    bottom   : auto !important;
    left     : auto !important;
    transform: none !important;


  }
}

body {
  display       : flex;
  flex-direction: column;
  min-height    : 100vh;
  padding-top   : 106px;
  background    : var(--bg3);

  @media (max-width:theme('screens.lg')) {
    padding-top: 72px;
  }

  * {
    font-family: var(--font-base);
  }

  &.menu-opened {
    overflow: hidden;
  }
}

.header {
  display              : grid;
  align-items          : center;
  grid-template-columns: 1fr auto 1fr;
  position             : fixed;
  top                  : 0;
  left                 : 0;
  width                : 100%;
  padding-top          : 15px;
  padding-bottom       : 15px;
  background           : #fff;

  border-bottom: 1px solid rgba(0, 0, 0, 0);
  z-index      : theme('zIndex[3]');

  @media (max-width:theme('screens.lg')) {
    padding-top   : 8px;
    padding-bottom: 8px;
  }

  &-nav {
    display    : flex;
    align-items: center;
    gap        : 64px;
  }

  &-contacts {
    display    : flex;
    align-items: center;
  }

  &-logo {
    width     : 75px;
    transition: .25s;

    @media (max-width:theme('screens.lg')) {
      width: 46px;
    }
  }

  &:not(:is(.scroll-top &)) &-logo {
    width: 30px;
  }

  &:not(:is(.scroll-top &)) {
    border-bottom: 1px solid theme('colors.gray / 20%');
  }
}

.started {
  display    : flex;
  align-items: center;
  height     : 768px;
  gap        : 100px;

  @media (max-width:theme('screens.lg')) {
    flex-direction: column;
    height        : auto;
    gap           : 20px;
  }

  @media (max-width:theme('screens.sm')) {
    padding-top   : 8px;
    padding-bottom: 8px;
  }

  &-content {
    display        : flex;
    justify-content: center;
    flex-direction : column;
  }

  &-content-slider {
    width: calc(50% - 50px);

    @media (max-width:theme('screens.lg')) {
      display       : flex;
      flex-direction: column;
      width         : 100%;

      .slider-pagination {
        order: -1;
      }
    }
  }

  &-image {
    align-self: center;
    margin    : auto;
    padding   : 32px;

    background: #FFF;
    border    : 9px solid #2B2B2B;

    box-shadow: 12px 12px 5px 0 rgba(0, 0, 0, 0.2) inset, 0 5px 9px 0 rgba(0, 0, 0, 0.25);

    @media (max-width:theme('screens.sm')) {
      padding     : 20px;
      border-width: 5px;
      box-shadow  : 6px 6px 5px 0 rgba(0, 0, 0, 0.2) inset, 0 4px 6px 0 rgba(0, 0, 0, 0.25);
    }
  }

  &-image-slider {
    width: calc(50% - 50px);


    @media (max-width:theme('screens.lg')) {
      order    : -1;
      width    : 300px;
      max-width: 100%;
    }
  }

}

.curtains-canvas {
  position      : fixed;
  top           : -10%;
  right         : 0;
  left          : 0;
  height        : 120%;
  z-index       : 10;
  pointer-events: none;
}


.catalog {
  position      : relative;
  padding-top   : 82px;
  padding-bottom: 82px;

  @media (max-width:theme('screens.md')) {
    padding-top   : 42px;
    padding-bottom: 32px;
  }

  &-plane {
    position  : absolute;
    inset     : 0;
    max-width : 250px;
    max-height: 340px;

    img {
      // display: none;
    }
  }

  &-grid>* {
    break-inside: avoid-column;
  }

  &-item {
    display       : flex;
    align-items   : center;
    flex-direction: column;
    position      : relative;
    padding       : 58px 42px 32px;
    background    : linear-gradient(180deg, #EEE 0%, rgba(238, 238, 238, 0.50) 100%);
    text-align    : center;


    @media (max-width:theme('screens.md')) {
      padding: 15px;
    }

    &-status {
      display      : flex;
      align-items  : center;
      position     : absolute;
      top          : 0;
      left         : 0;
      height       : 38px;
      padding      : 10px;
      padding-right: 16px;
      background   : #C1DEC9;
      font-family  : var(--font-alt);
      color        : #1F7439;
      gap          : 5px;

      &::before {
        content      : '';
        position     : absolute;
        top          : 0;
        right        : -12px;
        width        : 18px;
        height       : 100%;
        border-top   : 19px solid #C1DEC9;
        border-right : 10px solid rgba(0, 0, 0, 0);
        border-bottom: 19px solid #C1DEC9;
      }
    }

    &-plane {
      filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, .25));

      img {
        // opacity: 0;
      }
    }

    &-hover {
      display        : flex;
      align-items    : center;
      justify-content: flex-end;
      flex-direction : column;
      position       : absolute;
      inset          : 0;
      overflow       : hidden;
      padding        : 40px 20px;
      transition     : .25s;

      &-gradient {
        position  : absolute;
        inset     : 0;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%);
        opacity   : 0.8;
      }

      img {
        position  : absolute;
        top       : 0;
        left      : 0;
        width     : 100%;
        height    : 100%;
        transition: .25s;
        object-fit: cover;
      }
    }

    &:not(:hover) &-hover {
      opacity: 0;

      img {
        transform: scale(1.1);
      }

      &-btn {
        transform: translateY(30px);
      }
    }
  }

}

.about-bg {
  position  : absolute;
  inset     : 0;
  overflow  : hidden;
  background: url("../img/about-bg/bg.png") no-repeat center/ cover, #B6A380;

  &-grid {
    display              : grid;
    grid-template-columns: 1fr 1fr 1fr;
    position             : absolute;
    top                  : 50%;
    left                 : 50%;
    width                : 110%;
    transform            : translate(-50%, -50%) rotate(30deg);
    gap                  : 32px;
  }

  &-item {
    padding   : 45px;
    background: #FFF;
    border    : 15px solid #2B2B2B;
    box-shadow: 18px 18px 8px 0 rgba(0, 0, 0, 0.40) inset, 0 8px 15px 0 rgba(0, 0, 0, 0.25);
  }
}

.about {
  padding-top   : 48px;
  padding-bottom: 48px;

  @media (max-width:theme('screens.sm')) {
    padding-top   : 40px;
    padding-bottom: 40px;
  }

  &-content {
    position  : relative;
    width     : 490px;
    max-width : 100%;
    padding   : 80px 20px;
    background: #000;
    color     : #fff;

    &::before {
      content       : '';
      position      : absolute;
      inset         : 16px;
      border        : 1px solid #FFF;
      opacity       : 0.3;
      pointer-events: none;

      @media (max-width:theme('screens.xl')) {
        inset: 8px;
      }
    }

    @media (max-width:theme('screens.sm')) {
      padding: 40px 15px;

    }
  }
}

.slider {
  overflow      : hidden;
  padding-top   : 82px;
  padding-bottom: 82px;
  background    : url("../img/slider-bg.jpg") no-repeat center / cover, #73A4A7;

  @media (max-width:theme('screens.sm')) {
    padding-top   : 16px;
    padding-bottom: 16px;
  }

  &-item {
    display : flex;
    position: relative;
    gap     : 30px;

    @media (max-width:theme('screens.xl')) {
      flex-direction: column;
      gap           : 0;
    }

    &-prev {
      right: calc(100% + 32px);
    }

    &-next {
      left: calc(100% + 32px);
    }

    &-prev,
    &-next {
      position : absolute;
      top      : calc(50% - 56px / 2);
      font-size: 56px;
      color    : #fff;

      @media (max-width:theme('screens.xl')) {
        display: none;
      }
    }
  }

  &-content-swiper {
    width: calc(2 * 100% / 3 - 15px);

    @media (max-width:theme('screens.xl')) {
      width   : 100%;
      overflow: visible;
    }
  }

  &-product-swiper {
    width: calc(100% / 3 - 15px);

    @media (max-width:theme('screens.xl')) {
      order   : -1;
      width   : 100%;
      overflow: visible;
    }
  }

  &-content {
    position  : relative;
    padding   : 80px 20px;
    background: #000;
    color     : #fff;

    &::before {
      content       : '';
      position      : absolute;
      inset         : 16px;
      border        : 1px solid #FFF;
      opacity       : 0.3;
      pointer-events: none;

      @media (max-width:theme('screens.xl')) {
        inset: 8px;
      }
    }

    @media (max-width:theme('screens.xl')) {
      padding: 40px;
    }

    @media (max-width:theme('screens.sm')) {
      padding: 40px 20px;
    }
  }

  &-product {
    padding   : 58px 20px;
    background: #fff;

    @media (max-width:theme('screens.xl')) {
      padding: 32px 15px;

      .catalog-item-plane {
        height: 250px;

        img {
          max-height: 100%;
        }
      }
    }
  }
}

.footer {
  display        : flex;
  align-items    : center;
  justify-content: space-between;
  margin-top     : auto;
  padding-top    : 10px;
  padding-bottom : 10px;
  background     : #000;
  color          : #fff;

  @media (max-width:theme('screens.sm')) {
    flex-direction: column;
    padding-top   : 20px;
    padding-bottom: 20px;
    font-size     : 14px;
    gap           : 6px;
  }
}

.menu {
  position: fixed;
  top     : 0;
  left    : 0;

  left        : -80px;
  width       : 430px;
  max-width   : calc(100% + 60px);
  height      : 100%;
  padding     : 16px;
  padding-left: 96px;
  background  : #fff;
  box-shadow  : 0 0 24px rgba(0, 0, 0, 0.5);
  transition  : 1s cubic-bezier(.28, 1.66, .26, 1);
  z-index     : theme('zIndex[5]');
  clip-path   : polygon(120% 0%, 0% 0%, 0% 100%, 120% 100%);

  &:not(.toggle-active) {
    transform: translateX(-80px);
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  }


  &-shadow {
    position  : fixed;
    inset     : 0;
    background: rgba(0, 0, 0, 0.5);
    transition: .5s;
    z-index   : theme('zIndex[4]');
  }

  &:not(.toggle-active)~&-shadow {
    opacity       : 0;
    pointer-events: none;
  }
}

.dialog-form {
  width    : 574px;
  max-width: 100%;
  padding  : 58px 42px;

  @media (max-width:theme('screens.sm')) {
    padding: 40px 20px;
  }
}

.dialog-policy,
.dialog-product {
  width    : 970px;
  max-width: 100%;
  padding  : 58px 42px;

  @media (max-width:theme('screens.lg')) {
    width: var(--section-width);
  }

  @media (max-width:theme('screens.sm')) {
    padding: 40px 20px;
  }
}

.product-detail {
  display: flex;
  gap    : 58px;

  @media (max-width:theme('screens.lg')) {
    flex-direction: column;
  }

  @media (max-width:theme('screens.sm')) {
    gap: 24px;
  }

  &-image {
    box-sizing  : border-box;
    position    : relative;
    width       : calc(50% - 58px / 2);
    max-width   : 100%;
    padding-left: 80px;

    @media (max-width:theme('screens.lg')) {
      width       : 400px;
      margin-right: auto;
      margin-left : auto;
      padding     : 0;
    }
  }

  &-thumbs {
    position: absolute;
    top     : 0;
    left    : 0;
    width   : 68px;
    height  : 100%;

    @media (max-width:theme('screens.lg')) {
      display: none;
    }
  }

  &-thumb {
    box-sizing: border-box;
    position  : relative;

    &:is(.swiper-slide-thumb-active)::before {
      content : '';
      position: absolute;
      inset   : 0;
      border  : 6px solid #fff;
    }

    &:is(.swiper-slide-thumb-active)::after {
      content : '';
      position: absolute;
      inset   : 0;
      border  : 2px solid #2B2B2B;
    }
  }

  &-slide {
    box-sizing: border-box;
    padding   : 18px;
    background: #FFF;
    border    : 6px solid #2B2B2B;
    box-shadow: 7px 7px 3px 0 rgba(0, 0, 0, 0.40) inset, 0 3px 6px 0 rgba(0, 0, 0, 0.25);
  }
}

.pray {
  padding-top   : 80px;
  padding-bottom: 80px;

  @media (max-width:theme('screens.sm')) {
    padding-bottom: 40px;
  }

  &-content {
    position  : relative;
    padding   : 80px 20px;
    background: #000;
    color     : #fff;

    &::before {
      content       : '';
      display       : none;
      position      : absolute;
      inset         : 16px;
      border        : 1px solid #FFF;
      opacity       : 0.3;
      pointer-events: none;

      @media (max-width:theme('screens.lg')) {
        display: block;
        inset  : 8px;
      }
    }

    @media (max-width:theme('screens.xl')) {
      padding: 40px;
    }

    @media (max-width:theme('screens.sm')) {
      padding: 40px 20px;
    }
  }
}

.dialog-message {
  width    : 360px;
  max-width: 100%;
  padding  : 40px 20px;
}

.not-found {
  display        : flex;
  align-items    : center;
  justify-content: center;
  flex-direction : column;
  flex-grow      : 1;

  &-title {
    font-family: var(--font-alt);
    font-size  : 228px;
    line-height: 0.75;

    @media (max-width:theme('screens.sm')) {
      font-size: 128px;
    }
  }
}